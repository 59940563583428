<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/produtos">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <ProdutoFormulario
          @send="criar"
          :listLinhas="listLinhas"
          :listMedicoAprovadores="listMedicoAprovadores"
          :listResponsavel="listResponsavel"
          :listEmpresasParceiras="listEmpresasParceiras"
          :loadingBtn="loadingBtn"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Produto criado com sucesso"
      @close="goToProdutos"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { criarProduto } from "@/services/produtos.js";
import { listarLinha } from "@/services/linhas";
import { listarMedicoAprovadores, listFuncionarios } from "@/services/user";
import { listarEmpresaParceira } from "@/services/empresas-parceiras";
import ProdutoFormulario from "@/components/produtos/ProdutoFormulario.vue";
export default {
  name: "ProdutoCriar",
  components: { ProdutoFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Produtos",
        disabled: false,
        to: "/produtos",
      },
      {
        text: "Criar Produto",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    listLinhas: [],
    listMedicoAprovadores: [],
    listResponsavel: [],
    listEmpresasParceiras: [],
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getLinhas();
    this.getMedicoAprovadores();
    this.getResponsavel();
    this.getEmpresasParceiras();
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await criarProduto(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goToProdutos() {
      this.sucess = false;
      this.$router.push({ name: "Produtos" });
    },
    async getLinhas() {
      const resp = await listarLinha();
      this.listLinhas = resp.data;
    },
    async getMedicoAprovadores() {
      const resp = await listarMedicoAprovadores();
      this.listMedicoAprovadores = resp.data;
    },
    async getResponsavel() {
      const resp = await listFuncionarios({ mesaId: 2 });
      this.listResponsavel = resp.data;
    },
    async getEmpresasParceiras() {
      const resp = await listarEmpresaParceira();
      this.listEmpresasParceiras = resp.data;
    },
  },
};
</script>

<style></style>
